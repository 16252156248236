import React from "react";
import "../styles/classes.scss";
import BachataBigInt from "../images/bachata-1.jpg";
import BachataIntAdv from "../images/bachata-2.jpg";
import BachataIntthree from "../images/Bachata-3-new.png";
import { Link } from "gatsby";

const BachataClasses = () => (
  <div className="container inner_content_swipe">
    <div className="classes-container">
      <div className="classes-content-text">
        <img src={BachataBigInt} alt="" width={300} height={200} />
        <div className="services_content">
          <h1 style={{marginTop: 0}}>Bachata Dance Classes Orange County</h1>
          <p>
            Welcome to <Link to="/">RF Dance</Link> - your #1 destination for bachata dance classes
            in Orange County. Our experienced instructors are dedicated to
            helping you master this exciting and sensual dance style, no matter
            your skill level.
          </p>
          <p>
            At RF Dance, we believe that dance should be accessible to everyone.
            That's why we offer range of bachata and <Link to="/classes/salsa-dance-classes-in-orange-county-ca">salsa classes</Link>, from beginner to
            advanced, to help you grow and develop as a dancer. Our classes are
            designed to be fun, challenging, and empowering, providing you with
            the skills and confidence to take your dancing to the next level.
          </p>
          <p>
            With convenient class times and locations, you'll have no problem
            fitting bachata classes into your busy schedule. Join our vibrant
            dance community today and discover the joy and passion of bachata
            dancing.
          </p>
        </div>
      </div>

      <div className="classes-content-text">
        <img src={BachataIntAdv} alt="" width={300} height={200} />
        <div className="services_content">
          <h2 style={{marginTop: 0}}>Bachata Dance Classes</h2>
          <p>
            Get ready to turn up the heat with RF Dance. Our Bachata dance
            classes will help you learn the basics of this sensual Latin dance
            style. Whether you're a beginner or an intermediate, our experienced
            instructors will help you step up your game and unleash your passion
            for dancing. Sign up for our new student special and take your first
            Bachata dance lessons in Orange County today. At our premier dance
            studio for bachata dance classes in Orange County. We offer a
            variety of classes to cater to dancers of all levels, from complete
            beginners to experienced dancers.
          </p>
          <p>
            Our beginner-level classes are designed to introduce you to the
            basic steps and techniques of bachata dancing, while also giving you
            the confidence to get out on the dance floor and have fun. Our
            intermediate and advanced classes build on these foundational
            skills, helping you refine your technique, develop your musicality,
            and explore more complex choreography.
          </p>
          <p>
            At RF Dance, we believe that everyone can learn to dance bachata, no
            matter their experience level. Join us today and start your journey
            to becoming a confident and skilled bachata dancer.
          </p>
        </div>
      </div>
      <div className="classes-content-text third_area">
        <img src={BachataIntthree} alt="" width={300} height={200} />
        <div className="services_content">
          <h2 style={{marginTop: 0}}>Why RF Dance for Bachata Dance Class in Orange County</h2>
          <p>We’re #1 in Orange County for a Bachata dance class.</p>
          <p>Whether you are a beginner or someone who wants to master this dance style, RF Dance has a class for you. Our Bachata beginner classes focus on powering you to become a skilled, confident dancer. On the other hand, our classes for advanced dancers help to become a better version of yourself. RF Dance proudly holds the top spot in Orange County when it comes to offering an exceptional Bachata dance class!.</p> 
          <p>Are you a beginner looking to unlock the secrets of Bachata? Our Bachata beginner classes are tailored to empower you, equipping you with the skills and confidence needed to become a proficient dancer in no time. We believe that everyone can dance, and our passionate instructors are here to guide you every step of the way. Our Bachata beginner classes focus on powering you to become a skilled, confident dancer. On the other hand, our classes for advanced dancers help to become a better version of yourself. At RF Dance, we are committed to creating a welcoming and inclusive environment where everyone can embrace the joy of Bachata. Our classes are not just about learning dance steps; they're about connecting with the music, expressing your emotions, and sharing the thrill of dance with fellow enthusiasts.</p>
          <p>So, join us at RF Dance, where our passion for Bachata shines through in every class. Whether you're looking to learn, grow, or simply have a fantastic time dancing, we have the perfect class waiting for you.</p>
          <p> Join our Bachata classes today and let's dance to the beat of your heart!</p>
        </div>
      </div>
     
    </div>
  </div>
);

export default BachataClasses;
