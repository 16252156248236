import React, { useState } from 'react';

// Individual FAQ item component
const FAQItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };
    const faqclass = isOpen ? 'faqs active' : 'faqs';

    const faqContentClass = isOpen ? 'faqs_content active_active' : 'faqs_content';

    return (
        <div className={faqclass}>
            <h3 itemProp="name" className='toggle_title' onClick={toggleOpen}>
                {question}
                <span className="arrow"></span>
            </h3>
            <div className={faqContentClass} itemScope itemType="https://schema.org/Answer" itemProp="acceptedAnswer">
                <p itemProp="text">{answer}</p>
            </div>
        </div>
    );
};

// FAQ list component
const FAQList = ({ items }) => {
    return (
         <div itemScope itemType="https://schema.org/FAQPage">
                {items.map((item, index) => (
                    <div key={index} itemProp="mainEntity" itemScope itemType="https://schema.org/Question">
                    <FAQItem key={index} question={item.question} answer={item.answer} />
                    </div>
                ))}
        </div>
    );
};

// Main App component with provided FAQ data
const FAQBachata = () => {
    const faqs = [
        {
            question: "What is bachata style dance?",
            answer: "Bachata style dance is a captivating and sensuous partner dance originating from the Dominican Republic. It features rhythmic hip movements, close embrace, and intricate footwork, creating an intimate and passionate dance experience."
        },
        {
            question: "IS BACHATA DANCING HARD?",
            answer: "While it may initially pose some challenges, dancing bachata is achievable for most individuals with patience and practice. With the guidance of experienced instructors at Bachata dance classes, learners can quickly grasp the fundamental steps and gradually build confidence in expressing themselves through this alluring dance form."
        },
        {
            question: "WHAT IS THE BACHATA DANCE CLASSES COST?",
            answer: "The cost of Bachata dance classes depends on different factors. Enrolling in these classes offers an invaluable opportunity to immerse oneself in the enchanting world of Bachata, honing skills and connections on the dance floor."
        },
        {
            question: "How fast can you learn to dance bachata?",
            answer: "The speed of learning Bachata can differ depending on an individual's prior dance experience, dedication, and practice frequency. Beginners can expect to develop a basic proficiency in a few weeks, while mastering more advanced techniques may take several months of consistent learning."
        },
        {
            question: "How long does it take to learn bachata?",
            answer: "The timeline to learn Bachata varies for each person and is influenced by factors such as previous dance background, regularity of practice, and individual learning abilities. With commitment and dedication, dancers can achieve proficiency and confidently showcase their Bachata skills in a social setting."
        },
        {
            question: "DO YOU MOVE YOUR HIPS IN BACHATA DANCE CLASSES?",
            answer: "Yes. In Bachata dance classes, you’ll learn that the dance type involves rhythmic hip movements, which are an essential element in the dance's sensuous and expressive character. Properly coordinating hip motion adds flair and passion to the dance, enhancing the connection between partners on the dance floor."
        },
        {
            question: "What ethnicity dances bachata?",
            answer: "Bachata dance originates from the Dominican Republic and is now widely enjoyed across various cultures and communities around the world. Dancers of diverse ethnicities embrace this enchanting dance style, showcasing its universal appeal and inclusivity."
        },
        {
            question: "Is dancing bachata intimate?",
            answer: "Bachata is often considered an intimate dance due to its close embrace and emphasis on connection between partners. The dance encourages a deep sense of trust and communication, allowing dancers to express emotions and tell captivating stories through their movements, creating an intimate and emotionally charged atmosphere."
        }
    ];

    return (
        <div className="faq_section">
            <h3 className='main_heading'>FAQ</h3>
            <FAQList items={faqs} />
        </div>
    );
};

export default FAQBachata;
